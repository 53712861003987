import React from "react";
import {selectAll} from "../searchHelper";

export default function SideBar({
  searchFilter,
  setSearchFilter,
  filteredServices,
  filteredStatus,
  setFilteredServices,
  setFilteredStatus,
  services,
}) {
  const filterChanged = (key, checked, type = "status") => {
    let newFilter;
    if (type === "services") newFilter = {...filteredServices};
    else if (type === "status") newFilter = {...filteredStatus};

    if (checked) {
      newFilter[key] = true;
    } else {
      delete newFilter[key];
    }

    if (type === "services") setFilteredServices(newFilter);
    else if (type === "status") setFilteredStatus(newFilter);
  };

  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
      style={{
        width: 300,
        height: "calc(100vh - 54px)",
        overflowY: "auto",
        position: "sticky",
        top: 54,
      }}
    >
      <input
        className="search-bar me-2"
        type="search"
        placeholder="Look for an update"
        value={searchFilter}
        onChange={(e) => setSearchFilter(e.target.value)}
      />
      <hr />
      <strong>Status</strong>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="filterNowAvailable"
          checked={filteredStatus["General Availability"] || false}
          onChange={(e) =>
            filterChanged("General Availability", e.target.checked)
          }
        />
        <label className="form-check-label" htmlFor="filterNowAvailable">
          General Availability
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="filterInPreview"
          checked={filteredStatus["Preview"] || false}
          onChange={(e) => filterChanged("Preview", e.target.checked)}
        />
        <label className="form-check-label" htmlFor="filterInPreview">
          Preview
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="filterPrivatePreview"
          checked={filteredStatus["Private Preview"] || false}
          onChange={(e) => filterChanged("Private Preview", e.target.checked)}
        />
        <label className="form-check-label" htmlFor="filterPrivatePreview">
          Private Preview
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="filterRetirement"
          checked={filteredStatus["Retirement"] || false}
          onChange={(e) => filterChanged("Retirement", e.target.checked)}
        />
        <label className="form-check-label" htmlFor="filterRetirement">
          Retirement
        </label>
      </div>
      <hr />
      <div style={{flexDirection: "row"}}>
        <strong>Services</strong>&nbsp;&nbsp;
        <a href="#all" onClick={() => selectAll(services, setFilteredServices)}>
          Select all
        </a>
      </div>
      {services.map((service) => (
        <div className="form-check" key={service.name}>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id={`filter${service.name}`}
            checked={filteredServices[service.name] || false}
            onChange={(e) =>
              filterChanged(service.name, e.target.checked, "services")
            }
          />
          <label className="form-check-label" htmlFor={`filter${service.name}`}>
            {service.displayName}
          </label>
        </div>
      ))}
    </div>
  );
}
