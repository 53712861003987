import {useRef, useState, useEffect} from "react";
import axios from "axios";
import {
  checkUpdateEndpoint,
  generateSpeakerNotesEndpoint,
  imageScraperEndpoint,
} from "../config";
import moment from "moment";
import {statusColors} from "../config";
import CopilotLogo from "../../assets/copilot.png";

export default function Update({
  update,
  triggerReload = async () => {},
  setError,
}) {
  const [dateCopied, setDateCopied] = useState(false);
  const [titleCopied, setTitleCopied] = useState(false);
  const [serviceCopied, setServiceCopied] = useState(false);
  const [detailsCopied, setDetailsCopied] = useState(false);
  const [notesCopied, setNotesCopied] = useState(false);

  const [checkLoading, setCheckLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [scrapeLoading, setScrapeLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [optimisticNotes, setOptimisticNotes] = useState("");

  const generateSpeakerNotes = () => {
    setNotesLoading(true);
    axios
      .post(generateSpeakerNotesEndpoint, {
        ...update,
      })
      .then((result) => {
        console.log("Notes:", result.data.notes);
        setOptimisticNotes(result.data.notes);
        setNotesLoading(false);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  useEffect(() => {
    // console.log(update.images);
    setImages(update.images);
  }, [update.images]);

  const CopyToClipboard = (element) => {
    var doc = document,
      text = doc.getElementById(element),
      range,
      selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setDetailsCopied(true);
  };
  const leftColumnRef = useRef(null);

  return (
    <div className="col-12">
      <div className="row">
        <div
          className="col-7"
          style={{position: "relative"}}
          ref={leftColumnRef}
        >
          {update.checked && (
            <div
              style={{
                position: "absolute",
                width: 6,
                backgroundColor: "green",
                top: 0,
                left: 0,
                height: "100%",
              }}
            />
          )}
          <div className="action-wrapper">
            <div
              className="mark-update-checked-wrapper"
              style={{
                backgroundColor: update.checked ? "green" : "rgb(150, 34, 34)",
              }}
              onClick={() => {
                setCheckLoading(true);
                if (
                  !(titleCopied && serviceCopied && detailsCopied && dateCopied)
                ) {
                  const r = window.confirm(
                    "You did not copy all the usual fields (service, title, details and date). Do you want to proceed?"
                  );
                  if (r === false) {
                    setCheckLoading(false);
                    return;
                  }
                }
                axios
                  .post(`${checkUpdateEndpoint}`, {
                    id: update.id,
                    checked: !update.checked,
                  })
                  .then(() => triggerReload())
                  .catch((e) => setError(e.message))
                  .finally(() => setCheckLoading(false));
              }}
            >
              {checkLoading && (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                  style={{marginRight: "8px"}}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {update.checked ? "Mark as not checked" : "Mark as checked"}
            </div>

            <span
              className="mark-update-checked-wrapper"
              style={{
                backgroundColor: "rgb(0, 110, 255)",
              }}
              onClick={() => {
                navigator.clipboard
                  .writeText(update.link)
                  .then(() => window.open(update.link, "_blank"));
              }}
            >
              🔗
            </span>
          </div>

          <div
            className="update-service"
            onClick={() => {
              navigator.clipboard.writeText(update.service);
              setServiceCopied(true);
            }}
          >
            <span className="cursor-pointer">{update.service}</span>{" "}
            {serviceCopied && <span>✅</span>}
          </div>
          <div
            className="update-title"
            onClick={() => {
              // remove "Public preview : " from the beginning of the title
              const title = update.title.replace(
                /^(General availability: |Generally available: |Public preview: |Private preview: )/i,
                ""
              );

              navigator.clipboard.writeText(title);
              setTitleCopied(true);
            }}
          >
            <span className="cursor-pointer">{update.title}</span>{" "}
            {titleCopied && <span>✅</span>}
          </div>
          <div style={{width: "100%", overflowX: "scroll"}}>
            <div
              className="cursor-pointer"
              ref={(el) => {
                if (el) {
                  el.style.setProperty("font-family", "Segoe UI", "important");
                  el.style.setProperty("font-size", "14px", "important");
                }
              }}
              dangerouslySetInnerHTML={{
                __html: update.detailsHTML,
              }}
              id={"htmlDetails-" + update.id}
              onClick={() => CopyToClipboard("htmlDetails-" + update.id)}
            ></div>
            {detailsCopied && (
              <span className="text-success">✅ Details copied</span>
            )}
          </div>
          <div style={{display: "flex", flexDirection: "row", marginTop: 16}}>
            <div>
              <img
                src={CopilotLogo}
                alt="Copilot"
                style={{width: 30}}
                onDoubleClick={generateSpeakerNotes}
              />
              {notesCopied && <span style={{margin: "0 auto"}}>✅</span>}
            </div>
            <div
              style={{
                whiteSpace: "pre-line",
                marginLeft: 8,
                background:
                  "linear-gradient(to bottom right, lavender, lightskyblue 100%)",

                borderRadius: 16,
                padding: "6px 10px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  optimisticNotes.length > 0 ? optimisticNotes : update.notes
                );
                setNotesCopied(true);
              }}
            >
              {notesLoading
                ? "Generating speaker notes..."
                : optimisticNotes.length > 0
                ? optimisticNotes
                : !update.notes
                ? "No speaker notes have been generated for this update."
                : update.notes}
            </div>
          </div>
        </div>
        <div
          className="col-5 update-images-container"
          style={{height: leftColumnRef.current?.clientHeight || 400}}
        >
          <div className="update-banner-wrapper">
            <div
              className="update-banner-status"
              style={{
                backgroundColor: statusColors[update.status] || "transparent",
              }}
            />
            <div className="update-banner-text">
              <strong>{update.status}</strong>
              <span
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    moment(update.date).format("MMMM YYYY")
                  );
                  setDateCopied(true);
                }}
              >
                {/* {moment(update.date).format("MMMM YYYY")}{" "} */}
                {update.effectiveDate}
                {dateCopied && <span>✅</span>}
              </span>
            </div>
          </div>
          <div className="update-images-list">
            {images.length > 0 &&
              images.map((image) => (
                <img
                  src={image}
                  alt={image}
                  key={image}
                  className="update-image"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              ))}
            <button
              className="btn btn-primary"
              style={{
                marginBottom: "10px",
                alignSelf: "flex-start",
              }}
              onClick={() => {
                setScrapeLoading(true);
                axios
                  .get(`${imageScraperEndpoint}?updateId=${update.id}`)
                  .then((res) => {
                    // console.log(res);
                    // console.log(res.data.images);
                    setImages(res.data.images);
                  })
                  .catch((e) => {
                    setError(e.message);
                  })
                  .finally(() => setScrapeLoading(false));
              }}
            >
              Scrape images{" "}
              {scrapeLoading && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
