// @ts-check

const config = {
  getUpdatesEndpoint: "/api/get-updates",
  checkUpdateEndpoint: "/api/check-update",
  imageScraperEndpoint: "/api/scrape-images",
  generateSpeakerNotesEndpoint: "/api/generate-speaker-notes",
  statusColors: {
    "IN PREVIEW": "#50e6ff",
    Preview: "#50e6ff",
    "NOW AVAILABLE": "#0078d4",
    "General Availability": "#0078d4",
    "IN DEVELOPMENT": "#737373",
    "PRIVATE PREVIEW": "#d83b01",
    "Private Preview": "#d83b01",
    Retirement: "#737",
  },
};

module.exports = config;
